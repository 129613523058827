/* TutorialSection.css */

.column {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.columnExpanded {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.row {
  gap: 1rem;
  display: flex;
  flex-direction: row;
  height: 100%;
}

.numberCircle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tutorialCard {
  height: 100%;
  padding: 0.3rem;
  transition: width 0.5s ease-in-out;
}

.tutorialContent {
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0.7rem;
  border-radius: 8px;
  flex-direction: column;
}

.tutorialContentClickable:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.tutorialContentNotClickable:hover {
  cursor: not-allowed;
  background-color: #f5f5f5;
}

.tutorialCardActive {
  width: 100%;
}

.tutorialCardInactive {
  width: 75px;
}

.children-container {
  opacity: 0;
  height: 100%;
  visibility: hidden;
  transition: opacity 0.8s 0.4s, visibility 0.1s 0.4s;
}

.children-container.active {
  opacity: 1;
  visibility: visible;
}

.children-container.inactive {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.8s 0s, visibility 0.1s 0s;
}
